import React, { Component } from 'react'
import { InputText } from 'primereact/inputtext'
import { InputMask } from 'primereact/inputmask'

class TextInput extends Component {
  render () {
    return (
      <>
        {this.props.label && (
          <label htmlFor={this.props.id}>{this.props.label}</label>
        )}
        {this.props.mask
          ? <InputMask {...this.props} value={this.props.value || ''} unmask="true" autoClear={false} />
          : <InputText {...this.props} value={this.props.value || ''} unmask="true" />
        }
      </>
    )
  }
}

export default TextInput
