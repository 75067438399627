import React, { Component } from 'react'

export default class ErrorPage extends Component {
    getMessage = () => {
      if (String(this.props.match?.params[0]).search('erro/') > 0) {
        return String(this.props.match?.params[0]).replace('/erro/', '')
      }

      if (this.props.message) {
        return this.props.message
      }
    }

    message = this.getMessage()
    title = this.props.redirectTo?.title || this.props.title
    buttonText = this.props.buttonText

    render () {
      return (
        <div className="ErrorPage" style={this.props.throughRoute && { backgroundColor: '#0d1117' }}>
          <h1>{this.title || 'Ops...!'}</h1>
          <p>{this.message || 'Página não existe!'}</p>
          {!this.props.hideBackButton &&
            <h2>
              <a href={this.props.redirectTo?.path || '/'} >{this.buttonText || 'Voltar a página inicial'}</a>
            </h2>
          }
        </div>
      )
    }
}
