import React, { Component } from 'react'
import { PublicFooter } from './PublicFooter'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { ProgressSpinner } from 'primereact/progressspinner'
import Message from '../utils/Message'

class PublicContent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      layoutMode: 'overlay',
      layoutColorMode: 'dark',
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false
    }

    this.onWrapperClick = this.onWrapperClick.bind(this)
    this.onToggleMenu = this.onToggleMenu.bind(this)
    this.onSidebarClick = this.onSidebarClick.bind(this)
    this.onMenuItemClick = this.onMenuItemClick.bind(this)
  }

  onWrapperClick (event) {
    if (!this.menuClick) {
      this.setState({
        overlayMenuActive: false,
        mobileMenuActive: false
      })
    }

    this.menuClick = false
  }

  onToggleMenu (event) {
    this.menuClick = true
    if (this.isDesktop()) {
      this.setState({
        staticMenuInactive: !this.state.staticMenuInactive
      })
    } else {
      const mobileMenuActive = this.state.mobileMenuActive
      this.setState({
        mobileMenuActive: !mobileMenuActive
      })
    }
    event.preventDefault()
  }

  onSidebarClick (event) {
    this.menuClick = true
  }

  onMenuItemClick (event) {
    if (!event.item.items) {
      this.setState({
        overlayMenuActive: false,
        mobileMenuActive: false
      })
    }
  }

  addClass (element, className) {
    if (element.classList)
      element.classList.add(className)
    else
      element.className += ' ' + className
  }

  removeClass (element, className) {
    if (element.classList)
      element.classList.remove(className)
    else
      element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ')
  }

  isDesktop () {
    return window.innerWidth > 1024
  }

  componentDidUpdate () {
    if (this.state.mobileMenuActive)
      this.addClass(document.body, 'body-overflow-hidden')
    else
      this.removeClass(document.body, 'body-overflow-hidden')
  }

  render () {
    // const logo = 'assets/layout/images/logo.png';

    const wrapperClass = classNames('layout-wrapper', {
      'layout-overlay': this.state.layoutMode === 'overlay',
      'layout-static': this.state.layoutMode === 'static',
      // 'layout-static-sidebar-inactive': this.state.staticMenuInactive && this.state.layoutMode === 'static',
      'layout-overlay-sidebar-active': this.state.overlayMenuActive && this.state.layoutMode === 'overlay',
      'layout-mobile-sidebar-active': this.state.mobileMenuActive
    })

    return (
      <div className={wrapperClass} onClick={this.onWrapperClick}>
        {this.props.children}

        <PublicFooter />

        <div className="layout-mask"></div>
        { this.props.loading &&
          <div className="loading-container">
            <ProgressSpinner />
          </div>
        }
        <Message />
      </div>
    )
  }
}

const mapStateToProps = ({ loading }) => ({ loading })

export default connect(mapStateToProps)(PublicContent)
