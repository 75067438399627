import React, { Component } from 'react'
import { Button } from 'primereact/button'
import Modal from './Modal'

class Confirm extends Component {
  render () {
    const actions = (
      <div>
        <Button label="Sim" icon="pi pi-check" onClick={this.props.onConfirm} />
        <Button label={this.props.cancelLabel || 'Cancelar'} icon="pi pi-times" onClick={this.props.onCancel} className="p-button-secondary" />
      </div>
    )

    return (
      <Modal
        header={this.props.title}
        visible={this.props.visible}
        width={30}
        onHide={this.props.onCancel}
        footer={actions}
        closable={this.props.closable}
      >
        {this.props.description}
      </Modal>
    )
  }
}

export default Confirm
