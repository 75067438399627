import React from 'react'
import { Crud } from '../../../classes/Crud'
import Container from '../../../components/layout/Container'
import Page from '../../../components/layout/Page'
import { getEmpresaDTO } from '../../../dtos/cadastro/EmpresaDTO'
import EmpresaForm from '../../../forms/cadastro/empresa/EmpresaForm'
import EmpresaService from '../../../services/cadastro/EmpresaService'

export default class Empresa extends Crud {
  constructor (props) {
    super(props, getEmpresaDTO, EmpresaService, 'empresas')
  }

  render () {
    const { model, dataLoaded, isNew } = this.state
    return (
      <Page>
        <Container>
          {(dataLoaded || isNew) && (
            <EmpresaForm
              model={model}
              isNew={isNew}
              onDelete={this.onDelete}
            />
          )}
        </Container>
      </Page>
    )
  }
}
