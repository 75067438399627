import React, { Component } from 'react'
import { InputTextarea } from 'primereact/inputtextarea'

class TextAreaInput extends Component {
  render () {
    return (
      <span>
        {this.props.label && <label htmlFor={this.props.name}>{this.props.label}</label>}
        <InputTextarea {...this.props} rows={this.props.rows || 3} cols={this.props.cols || 30} autoResize={true} maxLength="250" />
      </span>
    )
  }
}

export default TextAreaInput
