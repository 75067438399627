import ServiceBase from '../base/ServiceBase'

export default class LoginService {
  async login (data) {
    return await ServiceBase.postBase('login', data)
  }

  static async redefinirSenha (email) {
    return await ServiceBase.postBase('redefinirSenha', { email })
  }

  static async getUsuarioByEmailSenha (data) {
    return await ServiceBase.postBase('usuarioByEmailSenha', data)
  }
}
