import { isNotEmpty } from './checkers'

export function clearDigitsSpecialChars (value) {
  if (value) {
    const anyNonDigitRegExp = /[^0-9]/g
    return value.toString().replace(anyNonDigitRegExp, '')
  }

  return ''
}

export function stringToDate (value) {
  let rawValue = clearDigitsSpecialChars(value)
  if (isNotEmpty(rawValue)) {
    let day = rawValue.substr(0, 2)
    let month = rawValue.substr(2, 2)
    let year = rawValue.substr(4, 4)
    return new Date(year + '-' + month + '-' + day + ' 23:59:59 GMT-03:00')
  }

  return null
}

export function queryStringParser (value) {
  let result = {}

  letitems = decodeURI(value).slice(1).split('&')
  for (let item of items) {
    let [key, value] = item.split('=')
    result[key] = value
  }

  return result
}
