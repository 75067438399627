import React from 'react'
import UsuarioForm from '../../../forms/cadastro/usuario/UsuarioForm'
import UsuarioService from '../../../services/cadastro/UsuarioService'
import Container from '../../../components/layout/Container'
import Page from '../../../components/layout/Page'
import { Crud } from '../../../classes/Crud'
import { getUsuarioDTO } from '../../../dtos/cadastro/UsuarioDTO'

export class Usuario extends Crud {
  constructor (props) {
    super(props, getUsuarioDTO, UsuarioService, 'usuarios')
  }

  render () {
    const { model, dataLoaded, isNew } = this.state
    return (
      <Page>
        <Container>
          {(dataLoaded || isNew) && (
            <UsuarioForm
              model={model}
              isNew={isNew}
              onDelete={this.onDelete}
            />
          )}
        </Container>
      </Page>
    )
  }
}
