export function authToken () {
  return localStorage.getItem('accessToken')
}

export function credentials () {
  return localStorage.getItem('credentials')
}

export function setCredentials (credentials) {
  localStorage.setItem('credentials', credentials)
}

export function login (response) {
  localStorage.setItem('accessToken', response.token)
  setCredentials(response.credentials)
}

export function logout () {
  localStorage.removeItem('accessToken')
  localStorage.removeItem('credentials')
}

export function isLoggedIn () {
  const token = localStorage.getItem('accessToken')
  const credentials = localStorage.getItem('credentials')
  return !!token && token !== 'null' && !!credentials && credentials !== 'null'
}
